.table {
  padding: 0 40px 0px 40px;
}

.table td:nth-child(6),
.table td:nth-child(7),
.table td:nth-child(8) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.inactive {
  color: var(--dark-grey);
}
