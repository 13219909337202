.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
}
.header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 40px;
  margin-bottom: 5px;
  border-radius: var(--radius-s);
  box-shadow: 0px 8px 20px 0px rgba(151, 155, 166, 0.4);
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}
.submit-btn {
  margin-left: auto;
  width: 256px;
}
.description {
  margin: 0 40px;
}
.date {
  margin-left: 40px;
}
.date label {
  white-space: nowrap;
}
.checkbox {
  margin: 0 40px;
  max-width: 220px;
}
