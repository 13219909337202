.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 20px;
}
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  border-radius: var(--radius-l);
  background: var(--white);
  box-shadow: 0px 8px 20px 0px rgba(151, 155, 166, 0.40);
  padding: 14px 40px 42px;
}
.form header {
  font-size: var(--font-size-xl);
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
  line-height: 80px;
  margin-bottom: 20px;
}
.field {
  width: 320px;
  margin-bottom: 18px;
}
.field input {
  height: 60px;
  border-radius: var(--radius-m);
}
.submit {
  width: 320px;
  margin-bottom: 18px;
  height: 60px;
  border-radius: var(--radius-m);
}