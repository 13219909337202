.wrapper {
  grid-area: nav;
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  align-items: center;
  width: 124px;
  height: 60px;
  padding-left: 40px;
  border-top: 1px solid var(--grey);
  background-color: transparent;
  cursor: pointer;
}
.item__active {
  background-color: var(--dark-grey);
  color: var(--white);
}
.item:hover {
  background-color: var(--grey);
  color: var(--white);
}
.change-pass {
  height: 40px !important;
  margin-top: auto;
  margin-bottom: 71px;
  border-bottom: 1px solid var(--dark-grey) !important;
  border-radius: 0 !important;
}
