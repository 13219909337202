.app {
  display: grid;
  grid-template-areas:
    'header header header'
    'nav body empty'
    'footer footer footer';
  grid-template-rows: 60px 1fr 24px;
  grid-template-columns: 160px 1fr 40px;
  background-color: var(--light-grey);
  min-height: 100vh;
}
