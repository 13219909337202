.wrapper {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 40px;
  margin-bottom: 24px;
  border-radius: var(--radius-s);
  box-shadow: 0px 8px 20px 0px rgba(151, 155, 166, 0.4);
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.fields {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  padding: 0 40px;
  margin-bottom: 20px;
}
.field {
  max-width: 360px;
}
.upload {
  max-width: 250px;
}
.status {
  margin-left: auto;
}

.target-version,
.compatibility {
  padding: 20px;
  border-radius: var(--radius-s);
  margin: 0 40px;
}
.target-version header,
.compatibility header {
  font-weight: var(--font-weight-m);
  line-height: 20px;
  margin-bottom: 4px;
}
.compatibility {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  margin-bottom: 16px;
}
.compatibility input {
  background-color: var(--white);
}
.target-version {
  border: 1px solid var(--medium-grey);
}
.rule-row {
  display: flex;
  min-height: 40px;
  border-bottom: solid 1px var(--grey);
  font-size: 12px;
}
.target-version .rule-row {
  border-bottom: solid 1px var(--medium-grey);
}
.rule-row button {
  margin-top: 8px;
  margin-right: 8px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
}
.compatibilty-btn {
  background-color: var(--white) !important;
}
.compatibilty-btn:hover {
  background-color: var(--accent) !important;
}
.add-icon {
  width: 14px;
  height: 14px;
}
.add-icon path {
  fill: var(--dark-grey);
}
.rule-key {
  flex: 0 !important;
  padding-right: 12px;
}
.rule-key input {
  margin-top: 8px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  min-width: 135px;
}
.rule-key-empty {
  opacity: 0;
}
.rule-key-empty input {
  cursor: default;
}
.rule-border {
  min-height: 36px;
  border-right: 1px solid var(--grey);
}
.target-version .rule-border {
  background-color: var(--medium-grey);
}
.rule-add-wrapper {
  position: relative;
}
.rule-add-wrapper svg {
  position: absolute;
  top: 6.5px;
  right: 6px;
  cursor: pointer;
}
.rule-add-wrapper path {
  fill: var(--dark-grey);
}
.rule-add {
  flex: 0;
}
.rule-add input {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  width: 100px;
}
.rule-values {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 12px;
}
.rule-value {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 24px;
  padding: 0 8px;
  background-color: var(--white);
  border-radius: var(--radius-xs);
}
.rule-value svg {
  margin-left: 8px;
}
