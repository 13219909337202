.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 344px;
  padding: 16px 16px 40px 40px;
  overflow: visible;
}
.close {
  margin-left: auto;
  width: 24px;
  height: 24px;
}
.wrapper header {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin-bottom: 24px;
}
.add-row {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 8px;
  padding-right: 24px;
  border-bottom: 1px solid var(--dark-grey);
}
.search {
  margin: 8px 24px 16px 0;
}
.list {
  height: 208px;
  padding-right: 24px;
  gap: 20px;
}
.list-placeholder {
  margin: auto;
  color: var(--dark-grey);
}
.list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
  height: 24px;
}
.row-title {
  max-width: 288px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.submit {
  margin: 8px 24px 0 0;
}
