.settings {
  display: flex;
  margin-left: 40px;
  margin-top: 12px;
  gap: 20px;
}
.settings-input {
  min-width: 270px;
  flex: 0 !important;
}
.submit-btn {
  width: 222px;
}
.toggle-btn {
  margin-top: 12px;
}
.toggle-btn button {
  width: 180px;
  padding: 0 !important;
  white-space: nowrap;
}
.table {
  padding: 0 40px 40px 40px;
}
.table td:nth-child(5),
.table td:nth-child(6) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.table td:nth-child(1),
.table td:nth-child(2) {
  width: 25%;
}

.table td:nth-child(3),
.table td:nth-child(4) {
  width: 10%;
}
