.esn-filter {
  flex: 0.5;
}
.table {
  padding: 0 40px 0 40px;
}
.table td:nth-child(1) {
  width: 15%;
}
.table td:nth-child(2),
.table td:nth-child(3) {
  width: 10%;
}
.table td:nth-child(7) {
  width: 1%;
}
.filters > div {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.filters div:nth-child(1) {
  flex: 1;
}
.filters div:nth-child(2) {
  gap: 24px;
}
