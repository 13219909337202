.create-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 25px 40px 40px 40px;
}
.create-wrapper header {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}
.create-close {
  position: absolute !important;
  top: 27px;
  right: 16px;
  width: 24px !important;
  height: 24px !important;
}
.params-field {
  margin-bottom: 10px;
}
.toggleEye {
  position: relative;
  display: flex;
  cursor: pointer;
  z-index: 99;
  background: none;
  border: none;
  left: 280px;
  top: 33px;
}
.submit {
  margin-top: 20px;
}
.success {
  color: green;
}
