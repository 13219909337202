.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 344px;
  padding: 0 28px 30px 28px;
  text-align: center;
  border-top: 5px solid var(--accent);
}
.wrapper header {
  height: 30px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin: 32px 0 20px 0;
}
.confirm {
  margin-bottom: 30px;
}
.wrapper footer {
  width: 100%;
  display: flex;
  gap: 24px;
}
.wrapper footer button {
  width: 160px;
}
