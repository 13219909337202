.table {
  padding: 0 40px 0 40px;
}

.extraRowStyle {
  background-color: var(--light-grey);
}

.extraRowStyle td:first-child {
  padding-left: 20px;
}

.downloaded,
.requested,
.processing {
  color: var(--dark-grey);
}
.fail {
  margin-right: 6px;
}
.fail-reason {
  padding: 16px;
}

.export {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.arrow-up {
  transform: rotate(180deg);
}
.state {
  display: flex;
  gap: 4px;
  align-items: center;
}
