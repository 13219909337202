.wrapper {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
.header {
  display: flex;
  width: 240px;
  text-align: center;
  color: var(--light-black);
  font-size: 16px;
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.profile {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.profile path {
  fill: var(--dark-grey) !important;
}
.profile:hover path {
  fill: var(--light-black) !important;
}
