.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header {
  display: flex;
  align-items: center;
  height: 80px;
  border-radius: 12px;
  padding: 0 40px;
  margin-bottom: 4px;
  box-shadow: 0px 8px 20px 0px rgba(151, 155, 166, 0.4);
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.fields-row {
  display: flex;
  gap: 20px;
  padding: 0 40px;
}
.field {
  flex: 0.25 !important;
}
